import React, { useState, useEffect } from "react";
import "./sass/styles.css";
import MenuMob from "../../Menu/Mobile";
import { Link } from "react-router-dom";

function HeaderMob() {
  //scroll com mudança do header
  const [scrolling, setScrolling] = useState(false);
  function handleScrollToResults() {
    const teste = document.querySelector("body");
    teste.addEventListener("click", function () {
      window.scrollTo(0, 0);
    });
  }
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const offset = 50;

      if (scrollTop < offset) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [OpenMenu, SetOpenMenu] = useState(false);
  // const toggleMenu = () => {
  //   SetOpenMenu(!OpenMenu);
  // };

  let isMobile = false;
  if (window.innerWidth <= 1024) {
    isMobile = true;
  }

  return (
    <>
      {isMobile ? (
        <header>
          <div
            className={
              scrolling ? "header-container" : "header-container-ativo"
            }
          >
            <div className={"headercontainer-layout"}>
              <p
                className={"home-link"}
                onClick={() => {
                  handleScrollToResults(".about-us");
                }}
              >
                <svg
                  width="110"
                  height="24"
                  viewBox="0 0 110 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.0811 23.4668C49.9868 23.4668 48.9515 23.241 47.9753 22.7886C46.9982 22.337 46.1353 21.7161 45.3839 20.9282C44.6325 20.1394 44.0485 19.2307 43.6296 18.2005C43.2114 17.1711 43.002 16.0672 43.002 14.8896C43.002 13.3129 43.3562 11.8939 44.0639 10.6324C44.7724 9.37088 45.7381 8.37282 46.961 7.63662C48.184 6.90121 49.5468 6.53271 51.0487 6.53271C52.4431 6.53271 53.6984 6.85338 54.8145 7.49471C55.9298 8.13604 56.767 9.03453 57.325 10.191L56.6812 10.4113C56.1442 9.40224 55.3613 8.60881 54.3317 8.03098C53.3012 7.45316 52.1754 7.16385 50.9517 7.16385C49.5994 7.16385 48.3708 7.50569 47.266 8.18857C46.1611 8.87224 45.2812 9.79739 44.6268 10.964C43.9717 12.1306 43.645 13.44 43.645 14.8904C43.645 16.3408 43.9774 17.6392 44.6422 18.8481C45.307 20.0571 46.1975 21.0246 47.3137 21.7498C48.429 22.475 49.6519 22.838 50.9832 22.838C51.8632 22.838 52.7213 22.6757 53.5584 22.3488C54.3956 22.0234 55.1194 21.5977 55.7309 21.0716C56.3423 20.5463 56.7338 19.9889 56.9061 19.4001L57.5499 19.589C57.3137 20.3252 56.8632 20.9877 56.1976 21.5758C55.5327 22.1646 54.7547 22.6271 53.8642 22.9635C52.9737 23.2998 52.0452 23.4676 51.0795 23.4676L51.0811 23.4668Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M66.9707 23.4669C65.8627 23.4669 64.8193 23.2466 63.8415 22.8044C62.8636 22.363 62.0047 21.7483 61.2663 20.9596C60.527 20.1716 59.9511 19.2676 59.5387 18.2476C59.1254 17.2276 58.9199 16.1504 58.9199 15.0151C58.9199 13.8798 59.1318 12.7712 59.5556 11.7512C59.9795 10.7312 60.561 9.82723 61.2994 9.03929C62.0379 8.25134 62.8968 7.63589 63.8746 7.19448C64.8525 6.75308 65.8845 6.53198 66.9716 6.53198C68.0586 6.53198 69.0898 6.75308 70.0676 7.19448C71.0455 7.63589 71.9036 8.25134 72.6429 9.03929C73.3813 9.82801 73.9628 10.732 74.3867 11.7512C74.8105 12.7712 75.0224 13.8595 75.0224 15.0151C75.0224 16.1708 74.8153 17.2284 74.4036 18.2476C73.9903 19.2676 73.4096 20.1716 72.6599 20.9596C71.9101 21.7483 71.052 22.363 70.0846 22.8044C69.1173 23.2458 68.0804 23.4669 66.9724 23.4669H66.9707ZM59.5718 15.0786C59.5718 16.4875 59.9026 17.7804 60.5658 18.9572C61.2282 20.1348 62.1195 21.0756 63.2389 21.7797C64.3583 22.4845 65.5909 22.8365 66.9384 22.8365C68.2859 22.8365 69.5241 22.479 70.654 21.764C71.7839 21.0497 72.6857 20.0924 73.3595 18.8945C74.0324 17.6965 74.3697 16.3926 74.3697 14.9837C74.3697 13.5749 74.0324 12.2773 73.3595 11.0895C72.6857 9.90171 71.7896 8.95069 70.6702 8.23566C69.5508 7.52142 68.3182 7.16312 66.9707 7.16312C65.6233 7.16312 64.4174 7.52612 63.2875 8.25134C62.1567 8.97657 61.2549 9.93856 60.582 11.1365C59.9083 12.3345 59.5718 13.6493 59.5718 15.0786Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M76.4902 15.0339C76.4902 13.5238 76.834 12.1283 77.5215 10.8488C78.2089 9.56923 79.1463 8.53589 80.3353 7.74952C81.5242 6.96314 82.8717 6.56956 84.3761 6.56956C85.8804 6.56956 87.2991 6.97412 88.4993 7.78088C89.6988 8.5892 90.637 9.53866 91.3132 10.6284V0.886963H91.9675V22.2069C91.9675 22.3958 92.0168 22.5479 92.1147 22.6632C92.2125 22.7785 92.257 22.8365 92.4099 22.8365V23.466C92.2134 23.466 92.1519 23.4457 92.0653 23.4033C91.8251 23.299 91.6399 23.136 91.5089 22.9157C91.3779 22.6953 91.3123 22.4594 91.3123 22.2077V19.7545C90.5925 20.8451 89.6276 21.7373 88.4168 22.4288C87.2061 23.1211 85.9573 23.4668 84.6705 23.4668C83.492 23.4668 82.401 23.2253 81.3981 22.7432C80.3943 22.261 79.5273 21.6157 78.7969 20.8074C78.0658 20.0007 77.4988 19.0982 77.0952 18.1018C76.6916 17.1053 76.4902 16.0829 76.4902 15.0339ZM91.3132 17.8344V11.9503C90.9864 11.0691 90.451 10.2725 89.7093 9.55904C88.9676 8.84636 88.1224 8.27403 87.1737 7.8436C86.225 7.41395 85.2924 7.19835 84.3761 7.19835C83.264 7.19835 82.2659 7.41866 81.3819 7.85928C80.4987 8.2999 79.74 8.89811 79.1075 9.65312C78.475 10.4081 77.9898 11.2478 77.6517 12.1706C77.3128 13.0942 77.1446 14.0484 77.1446 15.0339C77.1446 16.0194 77.3354 17.0425 77.7172 17.9763C78.0989 18.9101 78.6384 19.7435 79.3372 20.4781C80.0352 21.2128 80.8367 21.7898 81.7426 22.2092C82.6476 22.6287 83.6239 22.8388 84.6713 22.8388C85.3256 22.8388 86.0292 22.7079 86.7814 22.4452C87.5344 22.1834 88.2486 21.8157 88.9248 21.3437C89.6009 20.8717 90.1566 20.337 90.5941 19.7388C91.0301 19.1406 91.2703 18.5063 91.314 17.8352L91.3132 17.8344Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M101.96 23.4669C100.861 23.4669 99.821 23.2411 98.8399 22.7887C97.8589 22.3372 96.9975 21.7162 96.2542 20.9283C95.5109 20.1395 94.9286 19.2254 94.508 18.1842C94.0882 17.1438 93.8779 16.0352 93.8779 14.8568C93.8779 13.3225 94.2338 11.9238 94.9447 10.6623C95.6565 9.40077 96.6101 8.39722 97.8055 7.65083C99.0017 6.90523 100.332 6.53125 101.799 6.53125C103.265 6.53125 104.637 6.90993 105.855 7.66652C107.073 8.4231 108.048 9.43762 108.781 10.7101C109.514 11.9818 109.88 13.4063 109.88 14.983V15.3303H94.5242C94.6317 16.7392 95.0305 18.0109 95.7204 19.1462C96.4103 20.2814 97.3048 21.1807 98.404 21.8424C99.5032 22.5049 100.699 22.8358 101.993 22.8358C103.286 22.8358 104.493 22.5151 105.614 21.8738C106.734 21.2332 107.531 20.4077 108.006 19.3979L108.62 19.5554C108.318 20.2916 107.828 20.9588 107.15 21.5578C106.47 22.1568 105.678 22.6249 104.773 22.9612C103.868 23.2976 102.931 23.4654 101.961 23.4654L101.96 23.4669ZM94.525 14.7H109.202C109.116 13.2284 108.739 11.93 108.071 10.8057C107.403 9.68067 106.53 8.79237 105.453 8.14085C104.375 7.48933 103.168 7.16317 101.832 7.16317C100.496 7.16317 99.3204 7.48933 98.243 8.14085C97.1649 8.79237 96.2979 9.68616 95.6403 10.8214C94.9828 11.9567 94.6115 13.2495 94.525 14.7Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M7.95215 6.53198H9.86092C10.2589 6.53198 10.6956 6.84402 10.8323 7.20703L12.7597 12.3251L14.7137 7.19527C14.8488 6.83932 15.1181 6.53277 15.5088 6.53277H17.4297L14.5229 14.1064L17.1183 20.3026L22.2963 7.13019C22.4378 6.77111 22.7929 6.53355 23.19 6.53355H25.5436L18.4164 23.3893H16.6727C16.2901 23.3893 15.9447 23.1666 15.7975 22.8248L12.7839 15.8399L9.74688 22.8263C9.59887 23.1674 9.25351 23.3893 8.87175 23.3893H7.10209L0 6.53198H2.33825C2.72971 6.53198 3.08073 6.76562 3.22065 7.12078L8.4253 20.3018L11.0216 14.1056L7.95215 6.53198Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M26.8975 6.53198H41.4559V6.62136C41.4559 7.74016 40.5185 8.64884 39.3643 8.64884H28.9898C27.8357 8.64884 26.8983 7.74016 26.8983 6.62136V6.53198H26.8975Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M28.8936 13.9409H41.4559V14.1228C41.4559 15.1907 40.5614 16.0578 39.4598 16.0578H26.8975V15.8759C26.8975 14.808 27.792 13.9409 28.8936 13.9409Z"
                    fill="#0D98E6"
                  />
                  <path
                    d="M28.9898 21.3501H39.3643C40.5185 21.3501 41.4559 22.2588 41.4559 23.3776V23.467H26.8975V23.3776C26.8975 22.2588 27.8349 21.3501 28.989 21.3501H28.9898Z"
                    fill="#0D98E6"
                  />
                </svg>
              </p>

              <MenuMob
              //  OpenMenu={OpenMenu} toggleMenu={toggleMenu}
              />
            </div>
          </div>
        </header>
      ) : (
        <></>
      )}
    </>
  );
}

export default HeaderMob;

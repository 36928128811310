import React, { useState, useEffect } from "react";
import "./sass/styles.css";
import { Link } from "react-router-dom";

function MenuMob() {
  const [OpenMenu, SetOpenMenu] = useState(false);

  //scroll pela pagina

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const offset = 50;
      const btnMenu = document.querySelector(".botao-menu");
      console.log(btnMenu, "Antes do if");
      if (btnMenu == null || btnMenu == undefined) {
        return;
      }
      if (scrollTop > offset) {
        console.log(btnMenu, "TESTE");
        btnMenu.classList.add("ativo");
      } else {
        btnMenu.classList.remove("ativo");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollToSection(selector, offset = 60) {
    //console.log(offset, "AQUIIIITESTETESTE");
    const section = document.querySelector(selector);
    if (section) {
      const topOffset = section.offsetTop - offset;
      //console.log(section.offsetTop, "TESTEAQUIIII");
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  }

  function addClickEventToButton(buttonClass, sectionClass) {
    const button = document.querySelector(buttonClass);
    if (button) {
      button.addEventListener("click", function () {
        handleScrollToSection(sectionClass);
      });
    }
  }

  // addClickEventToButton("link-home");
  addClickEventToButton("link-clients");
  addClickEventToButton("link-about-us");
  addClickEventToButton("link-value");
  addClickEventToButton("link-methodology");
  addClickEventToButton("link-contato");

  //

  return (
    <>
      {OpenMenu ? (
        <div className={"menu-container-open"}>
          <div className={"menu-header"}>
            <p
              className={"link-home"}
            // onClick={() => {
            //   SetOpenMenu(false);
            //   handleScrollToSection(".first-fold");
            // }}
            >
              <svg
                width="110"
                height="23"
                viewBox="0 0 110 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.0811 22.9346C49.9868 22.9346 48.9515 22.7088 47.9753 22.2564C46.9982 21.8048 46.1353 21.1839 45.3839 20.3959C44.6325 19.6072 44.0485 18.6985 43.6296 17.6683C43.2114 16.6389 43.002 15.535 43.002 14.3574C43.002 12.7807 43.3562 11.3616 44.0639 10.1001C44.7724 8.83865 45.7381 7.84059 46.961 7.10439C48.184 6.36898 49.5468 6.00049 51.0487 6.00049C52.4431 6.00049 53.6984 6.32115 54.8145 6.96248C55.9298 7.60381 56.767 8.5023 57.325 9.65874L56.6812 9.87905C56.1442 8.87001 55.3613 8.07658 54.3317 7.49876C53.3012 6.92093 52.1754 6.63163 50.9517 6.63163C49.5994 6.63163 48.3708 6.97346 47.266 7.65634C46.1611 8.34001 45.2812 9.26516 44.6268 10.4318C43.9717 11.5984 43.645 12.9077 43.645 14.3582C43.645 15.8086 43.9774 17.107 44.6422 18.3159C45.307 19.5249 46.1975 20.4924 47.3137 21.2176C48.429 21.9428 49.6519 22.3058 50.9832 22.3058C51.8632 22.3058 52.7213 22.1435 53.5584 21.8166C54.3956 21.4912 55.1194 21.0655 55.7309 20.5394C56.3423 20.0141 56.7338 19.4567 56.9061 18.8679L57.5499 19.0568C57.3137 19.793 56.8632 20.4555 56.1976 21.0435C55.5327 21.6323 54.7547 22.0949 53.8642 22.4313C52.9737 22.7676 52.0452 22.9354 51.0795 22.9354L51.0811 22.9346Z"
                  fill="white"
                />
                <path
                  d="M66.9707 22.9346C65.8627 22.9346 64.8193 22.7143 63.8415 22.2721C62.8636 21.8307 62.0047 21.2161 61.2663 20.4273C60.527 19.6394 59.9511 18.7354 59.5387 17.7154C59.1254 16.6954 58.9199 15.6181 58.9199 14.4829C58.9199 13.3476 59.1318 12.239 59.5556 11.219C59.9795 10.199 60.561 9.295 61.2994 8.50706C62.0379 7.71912 62.8968 7.10366 63.8746 6.66225C64.8525 6.22085 65.8845 5.99976 66.9716 5.99976C68.0586 5.99976 69.0898 6.22085 70.0676 6.66225C71.0455 7.10366 71.9036 7.71912 72.6429 8.50706C73.3813 9.29579 73.9628 10.1998 74.3867 11.219C74.8105 12.239 75.0224 13.3272 75.0224 14.4829C75.0224 15.6385 74.8153 16.6962 74.4036 17.7154C73.9903 18.7354 73.4096 19.6394 72.6599 20.4273C71.9101 21.2161 71.052 21.8307 70.0846 22.2721C69.1173 22.7136 68.0804 22.9346 66.9724 22.9346H66.9707ZM59.5718 14.5464C59.5718 15.9553 59.9026 17.2481 60.5658 18.4249C61.2282 19.6026 62.1195 20.5434 63.2389 21.2474C64.3583 21.9523 65.5909 22.3043 66.9384 22.3043C68.2859 22.3043 69.5241 21.9468 70.654 21.2318C71.7839 20.5175 72.6857 19.5602 73.3595 18.3622C74.0324 17.1642 74.3697 15.8604 74.3697 14.4515C74.3697 13.0426 74.0324 11.7451 73.3595 10.5573C72.6857 9.36949 71.7896 8.41847 70.6702 7.70344C69.5508 6.98919 68.3182 6.63089 66.9707 6.63089C65.6233 6.63089 64.4174 6.9939 63.2875 7.71912C62.1567 8.44434 61.2549 9.40633 60.582 10.6043C59.9083 11.8023 59.5718 13.1171 59.5718 14.5464Z"
                  fill="white"
                />
                <path
                  d="M76.4902 14.5016C76.4902 12.9916 76.834 11.5961 77.5215 10.3165C78.2089 9.03701 79.1463 8.00366 80.3353 7.21729C81.5242 6.43091 82.8717 6.03733 84.3761 6.03733C85.8804 6.03733 87.2991 6.44189 88.4993 7.24865C89.6988 8.05698 90.637 9.00643 91.3132 10.0962V0.354736H91.9675V21.6747C91.9675 21.8636 92.0168 22.0157 92.1147 22.131C92.2125 22.2462 92.257 22.3042 92.4099 22.3042V22.9338C92.2134 22.9338 92.1519 22.9134 92.0653 22.8711C91.8251 22.7668 91.6399 22.6037 91.5089 22.3834C91.3779 22.1631 91.3123 21.9271 91.3123 21.6755V19.2222C90.5925 20.3128 89.6276 21.205 88.4168 21.8966C87.2061 22.5888 85.9573 22.9346 84.6705 22.9346C83.492 22.9346 82.401 22.6931 81.3981 22.2109C80.3943 21.7288 79.5273 21.0835 78.7969 20.2752C78.0658 19.4684 77.4988 18.566 77.0952 17.5695C76.6916 16.573 76.4902 15.5507 76.4902 14.5016ZM91.3132 17.3022V11.4181C90.9864 10.5368 90.451 9.74027 89.7093 9.02681C88.9676 8.31414 88.1224 7.7418 87.1737 7.31137C86.225 6.88173 85.2924 6.66612 84.3761 6.66612C83.264 6.66612 82.2659 6.88643 81.3819 7.32705C80.4987 7.76767 79.74 8.36588 79.1075 9.1209C78.475 9.87591 77.9898 10.7156 77.6517 11.6384C77.3128 12.562 77.1446 13.5161 77.1446 14.5016C77.1446 15.4872 77.3354 16.5103 77.7172 17.4441C78.0989 18.3779 78.6384 19.2113 79.3372 19.9459C80.0352 20.6805 80.8367 21.2576 81.7426 21.677C82.6476 22.0965 83.6239 22.3066 84.6713 22.3066C85.3256 22.3066 86.0292 22.1757 86.7814 21.913C87.5344 21.6512 88.2486 21.2834 88.9248 20.8115C89.6009 20.3395 90.1566 19.8048 90.5941 19.2066C91.0301 18.6084 91.2703 17.9741 91.314 17.303L91.3132 17.3022Z"
                  fill="white"
                />
                <path
                  d="M101.96 22.9347C100.861 22.9347 99.821 22.7089 98.8399 22.2565C97.8589 21.8049 96.9975 21.184 96.2542 20.396C95.5109 19.6073 94.9286 18.6931 94.508 17.652C94.0882 16.6116 93.8779 15.5029 93.8779 14.3246C93.8779 12.7902 94.2338 11.3915 94.9447 10.13C95.6565 8.86855 96.6101 7.865 97.8055 7.11861C99.0017 6.373 100.332 5.99902 101.799 5.99902C103.265 5.99902 104.637 6.37771 105.855 7.13429C107.073 7.89087 108.048 8.9054 108.781 10.1779C109.514 11.4496 109.88 12.8741 109.88 14.4508V14.7981H94.5242C94.6317 16.207 95.0305 17.4787 95.7204 18.614C96.4103 19.7492 97.3048 20.6485 98.404 21.3102C99.5032 21.9727 100.699 22.3036 101.993 22.3036C103.286 22.3036 104.493 21.9829 105.614 21.3416C106.734 20.701 107.531 19.8754 108.006 18.8656L108.62 19.0232C108.318 19.7594 107.828 20.4266 107.15 21.0256C106.47 21.6246 105.678 22.0927 104.773 22.429C103.868 22.7654 102.931 22.9331 101.961 22.9331L101.96 22.9347ZM94.525 14.1678H109.202C109.116 12.6961 108.739 11.3978 108.071 10.2735C107.403 9.14844 106.53 8.26015 105.453 7.60862C104.375 6.9571 103.168 6.63095 101.832 6.63095C100.496 6.63095 99.3204 6.9571 98.243 7.60862C97.1649 8.26015 96.2979 9.15393 95.6403 10.2892C94.9828 11.4245 94.6115 12.7173 94.525 14.1678Z"
                  fill="white"
                />
                <path
                  d="M7.95215 5.99976H9.86092C10.2589 5.99976 10.6956 6.3118 10.8323 6.6748L12.7597 11.7929L14.7137 6.66304C14.8488 6.30709 15.1181 6.00054 15.5088 6.00054H17.4297L14.5229 13.5742L17.1183 19.7703L22.2963 6.59797C22.4378 6.23888 22.7929 6.00132 23.19 6.00132H25.5436L18.4164 22.857H16.6727C16.2901 22.857 15.9447 22.6344 15.7975 22.2925L12.7839 15.3077L9.74688 22.2941C9.59887 22.6352 9.25351 22.857 8.87175 22.857H7.10209L0 5.99976H2.33825C2.72971 5.99976 3.08073 6.23339 3.22065 6.58856L8.4253 19.7696L11.0216 13.5734L7.95215 5.99976Z"
                  fill="white"
                />
                <path
                  d="M26.8975 5.99976H41.4559V6.08913C41.4559 7.20793 40.5185 8.11662 39.3643 8.11662H28.9898C27.8357 8.11662 26.8983 7.20793 26.8983 6.08913V5.99976H26.8975Z"
                  fill="white"
                />
                <path
                  d="M28.8936 13.4087H41.4559V13.5906C41.4559 14.6584 40.5614 15.5256 39.4598 15.5256H26.8975V15.3437C26.8975 14.2758 27.792 13.4087 28.8936 13.4087Z"
                  fill="white"
                />
                <path
                  d="M28.9898 20.8179H39.3643C40.5185 20.8179 41.4559 21.7266 41.4559 22.8454V22.9347H26.8975V22.8454C26.8975 21.7266 27.8349 20.8179 28.989 20.8179H28.9898Z"
                  fill="white"
                />
              </svg>
            </p>
            <div
              onClick={() => {
                SetOpenMenu(false);
              }}
            >
              <svg
                className={"botao-fechar"}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 13.4678L1 1.46777M13 1.46777L1 13.4678"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className={"menu-body"}>
            <p
              className={"institutional-links  link-clients"}
              onClick={() => {
                SetOpenMenu(false);
                handleScrollToSection(".our-clients");
              }}
            >
              Nossos Clientes
            </p>
            <p
              className={"institutional-links link-about-us"}
              onClick={() => {
                SetOpenMenu(false);
                handleScrollToSection(".about-us");
              }}
            >
              VTEX by Wecode
            </p>
            <p
              className={"institutional-links link-value"}
              onClick={() => {
                SetOpenMenu(false);
                handleScrollToSection(".how-generate-value");
              }}
            >
              Como geramos valor
            </p>
            <Link
              className={"institutional-links  link-methodology"}
              onClick={() => {
                SetOpenMenu(false);
                handleScrollToSection(".methodology");
              }}
            >
              O que fazemos
            </Link>
            <Link
              className={"institutional-links link-contato"}
              onClick={() => {
                SetOpenMenu(false);
                handleScrollToSection(".footer-container");
              }}
            >
              Contato
            </Link>
          </div>

          <div className={"menu-footer"}>
            <div className={"menu-address"}>
              <h5>ENDEREÇO</h5>
              <p>
                Rua Plácido de Castro, 1063 - Exposição, Caxias do Sul - RS,
                sala 403, 95084-370
              </p>
            </div>

            <div className={"menu-social-media"}>
              <h5>REDES SOCIAIS</h5>
              <div className={"menu-social-media-wrapper"}>
                <a
                  target="_blank"
                  href="https://www.instagram.com/wecode.digital/"
                >
                  Instagram
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.888889 8C0.644444 8 0.435111 7.91304 0.260889 7.73911C0.0869629 7.56489 0 7.35556 0 7.11111V0.888889C0 0.644444 0.0869629 0.435111 0.260889 0.260889C0.435111 0.0869629 0.644444 0 0.888889 0H3.55556C3.68148 0 3.78711 0.0425185 3.87244 0.127556C3.95748 0.212889 4 0.318519 4 0.444444C4 0.57037 3.95748 0.675852 3.87244 0.760889C3.78711 0.846222 3.68148 0.888889 3.55556 0.888889H0.888889V7.11111H7.11111V4.44444C7.11111 4.31852 7.15378 4.21289 7.23911 4.12756C7.32415 4.04252 7.42963 4 7.55556 4C7.68148 4 7.78696 4.04252 7.872 4.12756C7.95733 4.21289 8 4.31852 8 4.44444V7.11111C8 7.35556 7.91304 7.56489 7.73911 7.73911C7.56489 7.91304 7.35556 8 7.11111 8H0.888889ZM2.66667 5.33333C2.58519 5.25185 2.54444 5.14815 2.54444 5.02222C2.54444 4.8963 2.58519 4.79259 2.66667 4.71111L6.48889 0.888889H5.33333C5.20741 0.888889 5.10193 0.846222 5.01689 0.760889C4.93156 0.675852 4.88889 0.57037 4.88889 0.444444C4.88889 0.318519 4.93156 0.212889 5.01689 0.127556C5.10193 0.0425185 5.20741 0 5.33333 0H7.55556C7.68148 0 7.78696 0.0425185 7.872 0.127556C7.95733 0.212889 8 0.318519 8 0.444444V2.66667C8 2.79259 7.95733 2.89807 7.872 2.98311C7.78696 3.06844 7.68148 3.11111 7.55556 3.11111C7.42963 3.11111 7.32415 3.06844 7.23911 2.98311C7.15378 2.89807 7.11111 2.79259 7.11111 2.66667V1.51111L3.27778 5.34444C3.1963 5.42593 3.0963 5.46667 2.97778 5.46667C2.85926 5.46667 2.75556 5.42222 2.66667 5.33333Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/wecodedigital/?original_referer=https%3A%2F%2Fwww.wecode.digital%2F"
                >
                  Linkedin
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.888889 8C0.644444 8 0.435111 7.91304 0.260889 7.73911C0.0869629 7.56489 0 7.35556 0 7.11111V0.888889C0 0.644444 0.0869629 0.435111 0.260889 0.260889C0.435111 0.0869629 0.644444 0 0.888889 0H3.55556C3.68148 0 3.78711 0.0425185 3.87244 0.127556C3.95748 0.212889 4 0.318519 4 0.444444C4 0.57037 3.95748 0.675852 3.87244 0.760889C3.78711 0.846222 3.68148 0.888889 3.55556 0.888889H0.888889V7.11111H7.11111V4.44444C7.11111 4.31852 7.15378 4.21289 7.23911 4.12756C7.32415 4.04252 7.42963 4 7.55556 4C7.68148 4 7.78696 4.04252 7.872 4.12756C7.95733 4.21289 8 4.31852 8 4.44444V7.11111C8 7.35556 7.91304 7.56489 7.73911 7.73911C7.56489 7.91304 7.35556 8 7.11111 8H0.888889ZM2.66667 5.33333C2.58519 5.25185 2.54444 5.14815 2.54444 5.02222C2.54444 4.8963 2.58519 4.79259 2.66667 4.71111L6.48889 0.888889H5.33333C5.20741 0.888889 5.10193 0.846222 5.01689 0.760889C4.93156 0.675852 4.88889 0.57037 4.88889 0.444444C4.88889 0.318519 4.93156 0.212889 5.01689 0.127556C5.10193 0.0425185 5.20741 0 5.33333 0H7.55556C7.68148 0 7.78696 0.0425185 7.872 0.127556C7.95733 0.212889 8 0.318519 8 0.444444V2.66667C8 2.79259 7.95733 2.89807 7.872 2.98311C7.78696 3.06844 7.68148 3.11111 7.55556 3.11111C7.42963 3.11111 7.32415 3.06844 7.23911 2.98311C7.15378 2.89807 7.11111 2.79259 7.11111 2.66667V1.51111L3.27778 5.34444C3.1963 5.42593 3.0963 5.46667 2.97778 5.46667C2.85926 5.46667 2.75556 5.42222 2.66667 5.33333Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/wecode.digital"
                >
                  Facebook
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.888889 8C0.644444 8 0.435111 7.91304 0.260889 7.73911C0.0869629 7.56489 0 7.35556 0 7.11111V0.888889C0 0.644444 0.0869629 0.435111 0.260889 0.260889C0.435111 0.0869629 0.644444 0 0.888889 0H3.55556C3.68148 0 3.78711 0.0425185 3.87244 0.127556C3.95748 0.212889 4 0.318519 4 0.444444C4 0.57037 3.95748 0.675852 3.87244 0.760889C3.78711 0.846222 3.68148 0.888889 3.55556 0.888889H0.888889V7.11111H7.11111V4.44444C7.11111 4.31852 7.15378 4.21289 7.23911 4.12756C7.32415 4.04252 7.42963 4 7.55556 4C7.68148 4 7.78696 4.04252 7.872 4.12756C7.95733 4.21289 8 4.31852 8 4.44444V7.11111C8 7.35556 7.91304 7.56489 7.73911 7.73911C7.56489 7.91304 7.35556 8 7.11111 8H0.888889ZM2.66667 5.33333C2.58519 5.25185 2.54444 5.14815 2.54444 5.02222C2.54444 4.8963 2.58519 4.79259 2.66667 4.71111L6.48889 0.888889H5.33333C5.20741 0.888889 5.10193 0.846222 5.01689 0.760889C4.93156 0.675852 4.88889 0.57037 4.88889 0.444444C4.88889 0.318519 4.93156 0.212889 5.01689 0.127556C5.10193 0.0425185 5.20741 0 5.33333 0H7.55556C7.68148 0 7.78696 0.0425185 7.872 0.127556C7.95733 0.212889 8 0.318519 8 0.444444V2.66667C8 2.79259 7.95733 2.89807 7.872 2.98311C7.78696 3.06844 7.68148 3.11111 7.55556 3.11111C7.42963 3.11111 7.32415 3.06844 7.23911 2.98311C7.15378 2.89807 7.11111 2.79259 7.11111 2.66667V1.51111L3.27778 5.34444C3.1963 5.42593 3.0963 5.46667 2.97778 5.46667C2.85926 5.46667 2.75556 5.42222 2.66667 5.33333Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            SetOpenMenu(true);
          }}
        >
          <svg
            className={"botao-menu"}
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H20V0.0844444C20 1.14148 18.7122 2 17.1267 2H2.87444C1.28889 2 0.0011122 1.14148 0.0011122 0.0844444V0H0Z"
              fill="#353A42"
            />
            <path
              d="M2.74222 7H20V7.17185C20 8.18074 18.7711 9 17.2578 9H0V8.82815C0 7.81926 1.22889 7 2.74222 7Z"
              fill="#353A42"
            />
            <path
              d="M2.87444 14H17.1267C18.7122 14 20 14.8585 20 15.9156V16H0V15.9156C0 14.8585 1.28778 14 2.87334 14H2.87444Z"
              fill="#353A42"
            />
          </svg>
        </div>
      )}
    </>
  );
}

export default MenuMob;
